<template>
  <CsTableCard
    :columns="columns"
    :loading="loading"
    :rows="rows"
    card-header="Elenco Permessi"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'stato'">
        <FeatherIcon
          v-if="props.row.stato"
          icon="CheckIcon"
        />
        <span v-else>-</span>
      </span>
      <cs-table-action-column-default
        v-else
        :show-actions="canUpdate"
        :props="props"
      >
        <cs-table-action-item-add
          v-if="!props.row.stato && canUpdate"
          :row="props.row"
          @click="addRow"
        />
        <cs-table-action-item-delete
          v-if="props.row.stato && canUpdate"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Permesso',
          field: 'permesso',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Stato',
          field: 'stato',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      utente: {},
      canUpdate: this.$grants.UTENTI_AGGIORNA_PERMESSI.can,
    }
  },
  created() {
    if (!this.$grants.UTENTI_VISUALIZZA_DETTAGLIO.can) this.$routing.ERRORE401.push()

    const { id } = this.$route.params

    this.$remote.utenti.single(id)
      .then(rUser => {
        this.utente = rUser.data
        this.setBreadcrumbData(this.$store, this.$route.name, { nominativo: this.utente.nominativo })

        this.$remote.values.permessi_all()
          .then(rPerm => {
            this.rows = rPerm.data.map(m => ({
              id: m.id, permesso: m.permesso, permessoId: m.permessoId, stato: this.hasPermesso(m.permessoId),
            }))
          })
          .catch(err => {
            this.showReposonseMessage(err)
            this.$routing.ERRORE404.push()
          })
          .finally(() => { this.loading = false })
      })
      .catch(err => {
        this.showReposonseMessage(err)
        this.$routing.ERRORE404.push()
      })
      .finally(() => { this.loading = false })
  },
  methods: {
    hasPermesso(permessoId) {
      return this.utente.permessi.some(f => f.permessoId === permessoId)
    },
    addRow(params) {
      this.$remote.utenti.permesso_add(this.utente.id, params.id)
        .then(() => {
          this.rows[params.originalIndex].stato = true
          this.showAddSuccessMessage()
        })
        .catch(err => this.showReposonseMessage(err))
        .finally(() => { this.loading = false })
    },
    removeRow(params) {
      this.$remote.utenti.permesso_remove(this.utente.id, params.id)
        .then(() => {
          this.rows[params.originalIndex].stato = false
          this.showDeleteSuccessMessage()
        })
        .catch(err => this.showReposonseMessage(err))
        .finally(() => { this.loading = false })
    },
  },
}
</script>
